/* styles.css */
body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
  }
  
  .app {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
  }
  
  header {
    background-color: #2ecc71; /* Green color */
    padding: 20px;
    text-align: center;
    color: #fff;
  }
  
  .features {
    background-color: #d4efdf; /* Light green color */
    padding: 20px;
  }
  
  footer {
    background-color: #2ecc71; /* Green color */
    padding: 10px;
    text-align: center;
    color: #fff;
  }
  