@import "../../_globalColor";

.container {
  display: flex;
  justify-content: center;
}
.title-text {
  font-size: 30px;
  color: $textColorDark;
  margin:20px;
  margin-top: 5px;
  margin-bottom: 5px;
}
.app-image {
  width: 100px;
  height: 100px;
  border-radius: 20px;

  margin: 30px 0px 10px 40px;


  // position: absolute;
  // margin-left : 260px;
  // margin-top: 20px;
}

/* Rectangle Card Layout */
.rectangle {
  background: url(../../assets/images/talksCardBack.svg);
  width: 380px;
  height: 380px;
  border-radius: 4px;
  box-shadow: 0px 20px 50px $darkBoxShadow;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  margin-top: 50px;
}
.rectangle:hover {
  box-shadow: 0 20px 40px $textColorDark;
}
.dark-rectangle {
  box-shadow: 0px 0px 20px 0px;
}
.mask {
  clip: rect(0px, 460px, 220px, 0px);
  border-radius: 4px;
  position: absolute;
}

/* Card  Styling */
.talk-card-title {
  // color: $textColorDark;
  flex-direction: row;
  // justify-content: space-evenly;
  // align-items: center;
  // margin: auto;
  // text-align: left;
  // margin-top: 25px;
  // padding: 20px 0px 30px 20px;
  // font-size: 30px;
  // line-height: normal;
  // overflow: hidden;
  // display: -webkit-box;
  // -webkit-line-clamp: 2;
  // -webkit-box-orient: vertical;

}
.talk-card-subtitle {
  margin-top: 8px;
  text-align: justify;
  padding-left: 30px;
  padding-right: 30px;
  font-size: 16px;
  color: #ffffff;

  line-height: 18px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}
.talk-button {
  background-color: $greenLight;
  color: #ffffff;

  width: 90px;
  padding: 10px 18px;
  border-radius: 3px;
  text-align: center;
  text-decoration: none;
  display: block;
  margin-top: 20px;
  font-size: 14px;
  cursor: pointer;
}
.talk-button:hover {
  background: $greenDark;
}
.card-footer-button-div {
  display: flex;
  justify-content: space-around;
}

/* Media Query */
@media (max-width: 1380px) {
  .card-title {
    font-size: 25px;
  }
}
@media (max-width: 768px) {
  .mask {
    clip-path: none;
    position: relative;
  }
  .talk-card-title {
    margin-top: 30px;
  }
  .talk-card-image {
    width: 100%;
  }

  .talk-button {
    background-color: $greenDark;
    color: #ffffff;
    width: 90px;
    padding: 10px 18px;
    border-radius: 3px;
    text-align: center;
    text-decoration: none;
    display: block;
    margin-top: 20px;
    font-size: 14px;
    cursor: pointer;
    transition: 0.2s ease-in;
  }
  .talk-button:hover {
    background: $greenDark;
  }
  .card-footer-button-div {
    display: flex;
    justify-content: space-around;
  }

  .square {
    width: 100%;
    height: auto;
    padding-bottom: 30px;
  }
}
